import React from "react"
import "../styles/index.css"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  return (
    <div className="post-container">
    <Helmet>
     <meta charSet="utf-8" name="robots" content="noindex" name="theme-color" content="#000" />
      <title>{frontmatter.title} 〜 🌴 gs / xyz</title>
      <link rel="canonical" href="https://gagansingh.xyz" />
      <script src="https://kit.fontawesome.com/6b889176de.js" crossorigin="anonymous"></script>
      <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
    </Helmet>
      <div className="post">
      <a href="/" className="main-header">🌴🌴🌴</a>
      <br /><br /><br />
       <h3>{frontmatter.title}<br/>
       <small className="post-date">> {frontmatter.date}</small><br/><br/>
       <small className="post-subtitle">{frontmatter.subtitle}</small>
       </h3>
        <div
          className="post-content"
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </div>
      <br /><br /><br /><br />
      <a href="javascript:history.back()" className="back"> {"<"} go back </a>
      <br /><br /><br /><br /><br />
      <div className="top-bar"><a href="/">☺</a></div>
    </div>
  )
}

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        date(formatString: "MMM DD yyyy")
        slug
        title
        subtitle
        type
      }
    }
  }
`